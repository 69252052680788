import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import SEO from "@components/seo";
import CareersComponent from "@components/Careers";
import { MetadataService } from "../utils/MetadataService";

const CareersPage = ({ uri, data }) => {
  const { pageMetadata } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;
  return (
    <Layout path={uri}>
      <SEO title={metadata.title} />
      <CareersComponent metadata={metadata} />
    </Layout>
  );
} 


export const pageQuery = graphql`
  query CareersPageQuery {
    pageMetadata(page_id: {eq: "services_careers"}) {
      content,
      page_id
    }
  }
`

export default CareersPage;
