import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import slugify from "slugify";
import ContactForm from "@components/ContactForm";
import * as styles from "./Careers.module.scss";

import locationIcon from "@images/job_application/location.svg";

import home from "@images/careers/home.svg";
import experience from "@images/careers/experience.svg";
import schedule from "@images/careers/schedule.svg";
import teamImg from "@images/graphics/team_white.svg";
import team from "@images/careers/team.svg";

const benefits = [
  {
    image: home,
    content: "Enjoy working remotely from the comfort of your home"
  },
  { image: team, content: "Work alongside a friendly, top-quality team" },
  { image: schedule, content: "Flexible work schedule" },
  {
    image: experience,
    content: "Gain experience on modern, innovative projects"
  }
];

const IMAGE = {
  "home": home,
  "team": team,
  "schedule": schedule,
  "experience": experience,
  "teamImg": teamImg
}


const OpenPositions = ({ position, location, id }) => {
  const slug = slugify(id, { lower: true });

  return (
    <div className={styles.position}>
      <Link to={`/job-postings/${slug}`}>
        <h3>{position}</h3>
        <span className={styles.location}>
          <img src={locationIcon} alt="Location icon" />
          {location}
        </span>
        <button className={styles.apply_button}>Apply Now</button>
      </Link>
    </div>
  );
};

const CareersComponent = ({metadata}) => {
  const data = useStaticQuery(graphql`
    {
      allJobPostings {
        nodes {
          job_title
          location
          id
        }
      }
    }
  `);

  const jobPostings = data.allJobPostings.nodes;

  return (
    <div className={styles.careers}>
      <section className={styles.header}>
        {/* <h1 style={{ display: "block", margin: "auto 30px" }}>{metadata.title}</h1> */}

        <div className={`${styles.header_content} container`}>
          <p className={styles.why}>{metadata.subtitle}</p>

          <p>{metadata.description_1}</p>
          <img src={IMAGE[metadata.image_1]} className={styles.job_img} alt="Be a part of the team" />
        </div>
      </section>

      <section className={`${styles.main} container`}>
        <h2 className={styles.main_header}>{metadata.benefits_title}</h2>
        <ol className={styles.benefits}>
          {metadata.benefits.map((benefit, i) => (
            <li className={styles.benefit} key={i}>
              <span>{`${i + 1}.  ${benefit.content}`}</span>
              <img
                src={IMAGE[benefit.image]}
                className={styles.benefit_img}
                alt="Benefit"
              />
            </li>
          ))}
        </ol>

        <h2 className={styles.positions_title}>{metadata.positions_title}</h2>
        <div className={styles.positions}>
          {jobPostings?.length > 0 &&
            jobPostings.map((jobPosting, i) => (
              <OpenPositions {...jobPosting} />
            ))}
        </div>
      </section>

      <section className="container">
        <ContactForm />
      </section>
    </div>
  );
};

export default CareersComponent;
